// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

// temp fixes
@import "fixes";

// Spinkit
$spinkit-spinner-color: $body-color;
@import "~spinkit/scss/spinkit.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

@import "react-awesome-slider/dist/styles.css";

.k-sidebar {
    background-color: $k-primary;
    .nav-link {
        background-color: $k-primary;
        color: $k-primary;
        .nav-icon {
            color: $k-primary;
        }
    }
    .nav-dropdown.open a.nav-link {
        color: $k-primary;
    }
    .nav-title {
        color: $k-secondary;
        font-size: 20px;
    }
    .nav-link:hover {
        background-color: $k-primary;
    }
    .nav-link.active {
        border-left: 10px solid $k-primary;
        background-color: white;
        color: $k-primary;
        font-weight: bold;
        .nav-icon {
            color: $k-primary;
        }
    }
}
.card-header {
    background-color: white;
}
.breadcrumb-item a {
    color: $k-primary;
}
.form-control {
    background-color: #f2f2f2;
    font-weight: bold;
}
.form-control::placeholder {
    color: black;
}
.form-group label {
    color: #717171;
}
.table-menu-item td {
    vertical-align: middle;
    img {
        border-radius: 5px;
    }
}

.tags-card {
    height: 400px;
    overflow-y: scroll;
}
.fixed-height {
    max-height: 600px;
    overflow-y: scroll;
}
.hoverChooseFile {
    font-size: large;
    font-weight: bolder;
    display: flex;
    width: 100%;
    height: 164px;
    background: lightgrey;
    position: absolute;
    align-items: center;
    justify-content: center;
    opacity: 0;
}
.hoverChooseFile:hover {
    animation: makeVisible 2s infinite;
}

@keyframes makeVisible {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 0;
    }
}
.login-page {
    background-color: $k-secondary;
    .bg-secondary {
        background-color: $k-secondary;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-portion {
        height: 100%;
        .flex-view {
            display: flex;
            flex-direction: column;
            img {
                align-self: center;
            }
        }
        .card-body {
            padding: 3rem;
        }
        .card-footer {
            background-color: white;
            margin-bottom: 50px;
            border: 0;
        }
    }
}
.react-ruler-wrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.react-ruler-wrapper .ruler-list .rule-mark .line-text {
    font-size: 22px;
    top: -31px;
    margin-left: -6px;
}
.react-ruler-wrapper .ruler-list {
    border-bottom: 2px solid black;
}
.react-ruler-wrapper .ruler-list .rule-mark .line {
    border-left: 2px solid black;
    height: 30px;
}
.react-ruler-wrapper .ruler-list .line {
    height: 33px;
    border-left: 1px solid black;
}
.react-ruler-wrapper .ruler-drag .ruler-point .ruler-line {
    width: 3px;
    background-color: red;
    height: 58px;
}
.react-ruler-wrapper .ruler-drag {
    left: -2px;
}

.react-ruler-wrapper .ruler-drag .ruler-point .point {
    font-size: 20px;
    background-color: red;
}
.react-ruler-wrapper .ruler-drag .ruler-point .point:after {
    border-color: red transparent transparent transparent;
}
.awssld__content > img {
    object-fit: contain !important;
}
.awssld__content {
    background-color: #e4e5e6 !important;
}
.card {
    padding-left: 1rem;
}
