// Here you can add other styles
.container {
    /* On ajoute une marge sur les cotés de l’écran */
    padding-right: 15px;
    padding-left: 15px;
    
    /* Et on centre */
    margin-right: auto;
    margin-left: auto;
    }
    .image-type-item{
        border: 4px solid lightgrey;
        width: 230px;
        height: 350px !important;
        border-radius: 10px;
        padding: 10px;
        margin-left: 2rem;
        align-items: center;
        align-content: center;
        box-shadow: 0px 2px 2px 0px #d1d1d1;
        
        img{
            border-radius: 10px;
            width: 100 !important;
            height: 250px !important;
        }
    }
    .image-type-item:hover{
        border-color: #017DC3;
        border-width: 4px;
    }
    .image-type-item.active{
        border-color: #017DC3;
        border-width: 4px;
    }

    /* Sur les grands écrans, on limite la largeur du contenu */
    @media (min-width: 1200px){
    .container {
    width: 1170px;
    }
    }